body {
  background-color: #282c34;
  font-size: 14px;
  color: white;
}

.App {
  display: flex;
  flex-direction: column;
  min-width: 960px;
  min-height: 100vh;
}

.App-form-valign-center {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
}

.App-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  width: 200px;
}

.App-logo-header {
  vertical-align: -11px;
  margin-right: 4px;
}

.App-fieldset {
  border: none;
  display: flex;
  align-items: center;
  padding-top: 40px;
}

.App-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: transparent;
  color: white;
  line-height: 32px;
  padding: 1px 12px;
  width: 200px;
  font-size: 16px;
}

.App-input-short {
  width: 100px;
}

.App-button {
  border: none;
  border-radius: 3px;
  background-color: #ff315f;
  color: white;
  line-height: 32px;
  padding: 1px 12px;
  border: 1px solid #ff315f;
  font-size: 16px;
  min-width: 120px;
  cursor: pointer;
}
.App-button_login {
  margin-left: 8px;
}

.App-button-minor {
  border-color: #ccc;
  background-color: transparent;
  color: #ccc;
}

.App-button:disabled {
  background-color: #333;
  color: #666;
  border-color: #333;
  cursor: default;
}

.App-login-error {
  color: red;
  height: 32px;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

.App-link:hover .App-link-text {
  text-decoration: underline;
}

.App-link-icon {
  width: 2.5ex;
  vertical-align: -0.5ex;
  margin-left: 4px;
}

.App-title {
  font-size: 24px;
  letter-spacing: 4px;
  font-weight: 300;
}

.App-header {
  display: flex;
  justify-content: space-between;
  padding: 24px 40px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.App-section {
  padding: 0 40px;
  flex-grow: 1;
}

.App-calibration-row {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 12px 0;
  justify-content: space-between;
}

.Menu-item {
  border: 1px solid #61dafb;
  color: #61dafb;
  line-height: 36px;
  border-radius: 3px;
  padding: 0 12px;
  cursor: pointer;
}

.Menu-item:hover {
  background-color: #61dafb;
  color: black;
}

.App-footer {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 40px;
  margin: 40px 0 0;
  font-size: 12px;
  display: flex;
}

.App-footer_link-container {
  margin-right: 60px;
}

.App-footer_link {
  color: #666;
  text-decoration: none;
}

.App-footer_link:hover {
  color: #61dafb;
  text-decoration: underline;
}

.Field-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}
.Field-label {
  font-size: 16px;
  color: hsl(0, 0%, 80%);
  padding-right: 24px;
  flex-basis: 100px;
}

.Field-control {
  flex-basis: 200px;
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
}

.Form-error {
  color: red;
  font-weight: bold;
}

.Form-success {
  color: hsl(100, 100%, 40%);
  font-weight: bold;
}
