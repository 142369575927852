.Calibration-list_item {
  padding: 24px 0;
  border-top: 1px solid hsl(0, 0%, 40%);
}

.Calibration-list_item:first-child {
  border-top: none;
}

.Calibration-list_item-row {
  display: flex;
  padding: 0 0 12px;
  min-height: 50px;
}

.Calibration-list_item-row:first-child {
  padding-top: 0;
}

.Calibration-list_item-feild {
  font-weight: 600;
  flex-grow: 1;
  flex-basis: 200px;
}
.Calibration-list_item-feild:last-child {
  flex-grow: 0;
  flex-basis: 150px;
}

.Calibration-list_ref,
.Calibration-list_id,
.Calibration-list_lot,
.Calibration-list_testname,
.Calibration-list_field-status {
  font-size: 24px;
  flex-grow: 0;
  flex-shrink: 0;
}

.Calibration-list_lot,
.Calibration-list_testname {
  flex-grow: 1;
}

.Calibration-list_id {
  flex-basis: 100px;
}

.Calibration-list_field-name {
  font-size: 12px;
  font-weight: 400;
  color: hsl(0, 0%, 50%);
}

.Calibration-list_time {
  padding-top: 2px;
  color: hsl(0, 0%, 60%);
  font-size: 12px;
}

.Calibration-list_status-unknown {
  background-color: hsl(0, 0%, 40%);
}
.Calibration-list_status-created {
  background-color: hsl(200, 100%, 30%);
}
.Calibration-list_status-published {
  background-color: hsl(100, 100%, 40%);
}
.Calibration-list_status-uploaded {
  background-color: hsl(40, 100%, 40%);
}
.Calibration-list_status {
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 10px;
  border-radius: 13px;
  line-height: 26px;
}
