.Modal-shadow {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.1);
}

.Modal-container {
  width: 500px;
  background-color: hsla(220, 15%, 11%, 0.9);
  backdrop-filter: blur(3px);
}

.Modal-title {
  padding: 24px 40px;
  font-size: 16px;
  color: #61dafb;
  background-color: hsla(220, 5%, 10%, 1);
}

.Modal-content {
  padding: 40px;
  min-height: 150px;
}

.Modal-footer {
  display: flex;
  justify-content: flex-end;
  background-color: hsla(220, 5%, 10%, 0.9);
  padding: 24px;
}

.Modal-footer_button-container {
  padding-left: 24px;
}
